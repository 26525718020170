.login-register-content {
  background: $white;
}
.login-register-block
{
  max-width: 300px;
  margin: 0 auto;
  @include breakpoint(medium down) {
    padding: 20px 0;
  }
  h1 {
    font-size: 18pt;
    line-height: 22pt;
    font-weight: bold;
    text-align: center;
  }
  .social-block {
    border-top: 1px solid $grey-social;
    border-bottom: 1px solid $grey-social;
    padding: 10px 0 0;
    position: relative;
    h3 {
      font-size: 14pt;
      line-height: 18pt;
      text-align: center;
    }
    .social-list {
      list-style: none;
      margin: 20px 0;
      li {
        display: inline-block;
        width: 24px;
        margin-right: 41px;
        a {
          display: block;
          width: 24px;
          height: 24px;
          &.fb {
            background: url("../images/facebook.svg") no-repeat;
          }
          &.vk {
            background: url("../images/vk.svg") no-repeat;
          }
          &.gp {
            background: url("../images/google.svg") no-repeat;
          }
          &.ok {
            background: url("../images/odnoklassniki.svg") no-repeat;
          }
          &.tw {
            background: url("../images/twitter.svg") no-repeat;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .middle-text {
      position: absolute;
      padding: 0 20px;
      left: 50%;
      bottom: -10px;
      margin-left: -36px;
      display: block;
      background: $white;
    }
  }
  .login-register-form {
    margin-top: 20px;
    label {
      text-indent: 15px;
    }
    .input-group-button {
      width: 100%;
      input {
        width: 100%;
        background: $top-back-yellow;
        border-radius: 0;
        border: none;
        color: $black;
      }
      input[type="submit"] {
        &:hover {
          color: $category-name;
        }
      }
    }
  }
  .other-links {
    text-align: center;
    span {
      font-weight: bold;
    }
    & > * {
      color: $black;
      display: inline-block;
      margin-bottom: 20px;
    }
    a {
      @include underline();
      &:hover {
        color: $category-name;
      }
    }
  }
}