@import '../_styles/scss-helpers/sprite';
@import '../_styles/scss-helpers/variables';
@import "../_styles/search";
@import '../_modules/header/header';
@import '../_modules/cell/cell';
@import '../_modules/input/input';
@import "../_styles/login-register";
@import "../_styles/profile";
@import "../_styles/profile-edit";

body {
  font-family: 'Roboto Slab', serif;
  font-size: 12pt;
  background: $background-color url("../images/background.png") top repeat-x;
  &.empty {
    background: none;
    @include breakpoint(medium down) {
      .main-container {
        background: $white;
      }
    }
  }
}

.main-container {
  max-width: 1470px;
  overflow: hidden;
  margin: 0 auto;
  @include breakpoint(medium down) {
    padding: 0;
    background: $top-back-yellow;
  }
}
.main-content, .breadcrumbs-block {
  background: $white;
  padding: 40px 70px;
  @media screen and (max-width: 1440px) {
    padding: 30px;
  }
  @media screen and (max-width: 1280px) {
    padding: 20px;
  }
  @include breakpoint(medium down) {
    margin: 0 9px;
    padding: 0 20px;
  }
  @include breakpoint(small down) {
    padding: 0;
    margin: 0 -5px;
  }
  @include breakpoint(medium down) {
    background: transparent;
  }
}
.main-content {
  @include breakpoint(small down) {
    padding: 10px;
    background: $white;
  }
}
.main-index-content {
  background: $white;
  @include breakpoint(medium down) {
    padding-top: 15px;
  }
  @include breakpoint(small down) {
    padding: 0 5px!important;
  }
}
.category-title {
  text-align: center;
  color: $black;
  font-size: 30pt;
  margin-bottom: 20px;
  font-weight: bold;
  @include breakpoint(medium down) {
    font-size: 25pt;
    margin-bottom: 20px;
  }
  @include breakpoint(small down) {
    font-size: 18pt;
    line-height: 20pt;
    margin-bottom: 10px;
  }
}
.top-search-block {
  max-width: 600px;
  margin: 0 auto 40px;
}
.breadcrumbs-block {
  background: $breadcrumbs-grey;
  padding-top: 0;
  padding-bottom: 0;
  .breadcrumbs {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
    width: 100%;
    @include xy-grid-container(100%);
    @include breakpoint(small down) {
      overflow: visible;
    }
  }
  span, a {
    font-size: 10pt;
    color: $breadcrumbs-text;
    line-height: 14px;
    padding: 0 3px;
  }
  a {
    border-bottom: 1px solid $breadcrumbs-text-border;
    padding: 0;
    &:hover {
      text-decoration: none;
      border-bottom: none;
    }
  }
  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    @include breakpoint(small down) {
      word-wrap: break-word;
      white-space: normal;
    }
  }
  @include breakpoint(medium down) {
    padding: 0;
    background: $breadcrumbs-grey;
    margin: 0 30px;
    width: calc(100% - 60px);
  }
  @include breakpoint(small down) {
    margin: 0;
    width: 100%;
  }
}
.page-container {
  background: $white;
  .image-block {
    position: relative;
    margin-bottom: 40px;
    min-height: calc((100vw - 20px) * 0.2625);
    .info {
      text-align: center;
      position: absolute;
      top: 50%;
      margin: -100px 0 0 -300px;
      left: 50%;
      z-index: 3;
      p {
        margin-bottom: 5px;
      }
      h1 {
        font-weight: 700;
        color: $white;
        font-size: 20pt;
        line-height: 30pt;
        width: 600px;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        @include breakpoint(small down) {
          font-size: 14pt;
          line-height: 18pt;
          width: 300px;
        }
      }
      @include breakpoint(medium down) {
        margin-top: -50px;
      }
      @include breakpoint(small down) {
        margin: -50px 0 0 -150px;
      }
    }
    img {
      width: 100%;
    }
    @include breakpoint(small down) {
      margin-bottom: 30px;
    }
    &.no-image {
      background: $post-background;
      height: calc((100vw - 20px) * 0.4625);
      img {
        position: absolute;
        width: 600px;
        max-height: 80%;
        top: 20%;
        margin: -80px 0 0 -300px;
        left: 50%;
        @include breakpoint(medium down) {
          margin-top: -50px;
        }
        @include breakpoint(small down) {
          margin: -20px 0 0 -50%;
        }
      }
    }
    .layout {
      background: url("../images/overlay.png") repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  .main-page-block {
    .author-block, .tags-block, .social-block, .text-block, .share-block, .comments-block {
      @include breakpoint(medium down) {
        margin: 0 40px;
      }
      @include breakpoint(small down) {
        margin: 0 10px;
      }
    }
    .page-info {
      margin-bottom: 25px;
      .author-block {
        @include clearfix;
        margin-bottom: 25px;
        .image {
          float: left;
          width: 60px;
          margin-right: 15px;
          img {
            border-radius: 50%;
          }
        }
        .info {
          float: left;
          p {
            margin-bottom: 5px;
          }
          a {
            font-size: 12pt;
            color: $select-link;
            &:hover {
              color: $select-link;
            }
          }
        }
      }
      .tags-block {
        margin-bottom: 17px;
        p {
          margin-bottom: 10px;
        }
        .tag-links {
          a {
            border: 1px solid $grey-border;
            border-radius: 5px;
            color: $grey;
            margin: 0 12px 13px 0;
            padding: 4px 9px;
            display: inline-block;
            &:hover {
              color: $select-link;
            }
          }
        }
      }
    }
    .page-text {
      .text-block {
        h2 {
          font-size: 18pt;
          margin: 50px 0 25px;
        }
        p {
          margin-bottom: 25px;
          position: relative;
          &.first {
            @include breakpoint(medium down) {
                display: block;
                width: 45%;
                float: left;
                margin-right: 5%;
            }
            @include breakpoint(small down) {
              float: none;
              width: 100%;
              margin-right: 0;
            }
          }
        }
        img {
          width: 100%;
        }
        span, small {
          color: $grey-img-text;
          display: block;
          margin-top: 7px;
          font-size: 10pt;
        }
        .fact, .marker {
          display: block;
          position: absolute;
          right: -230px;
          width: 200px;
          font-size: 20pt;
          line-height: 28pt;
          color: $black;
          margin-bottom: 25px;
          @include breakpoint(medium down) {
            position: relative;
            width: 30%;
            float: right;
            right: initial;
            height: 100%;
            margin-left: 20px;
          }
          @include breakpoint(small down) {
            width: 100%;
            float: none;
            margin-left: 0;
          }
        }
      }
    }
    .share-block {
      p {
        text-align: center;
        margin: 5px 0;
      }
      .ya-share2 {
        text-align: center;
      }
    }
    .comments-block {
      margin-top: 30px;
      margin-bottom: 80px;
      button {
        cursor: pointer;
        font-size: 10pt;
      }
      .comments-block-title {
        font-size: 11pt;
        color: $breadcrumbs-text;
        b {
          font-weight: normal;
          color: $black;
          font-size: 18pt;
          margin-right: 15px;
        }
      }
      .fos_comment_comment_show {
        .fos_comment_comment_body {
          padding: 30px 0;
          border-bottom: 1px solid $input-border-border;
          .comment-image-block,
          .comment-text-block {
            float: left;
          }
          .comment-image-block {
            width: 10%;
            margin-right: 3%;
            @include breakpoint(small down) {
              width: 20%;
              margin-right: 5%;
            }
            img {
              border-radius: 50%;
            }
          }
          .comment-text-block {
            width: 87%;
            > span {
              display: block;
              color: $breadcrumbs-text;
              margin-bottom: 15px;
              .replay {
                display: inline-block;
                width: 17px;
                height: 17px;
                margin-right: 10px;
                background: url("../images/answer_icon.svg") no-repeat;
                background-size: contain;
              }
              a {
                color: $breadcrumbs-text;
                margin-right: 13px;
                &:last-child {
                  margin-right: 0;
                }
                &:hover {
                  color: $select-link;
                }
              }
            }
            p {
              margin-bottom: 15px;
            }
            .fos_comment_comment_metas {
              color: $black;
              font-size: 10pt;
              span {
                color: $breadcrumbs-text;
                margin-right: 20px;
              }
              button {
                cursor: pointer;
                margin-right: 10px;
                &:hover {
                  color: $select-link;
                }
              }
              a, button {
                color: $black;
                border-bottom: 1px solid $black-border;
                &:hover {
                  border-bottom: none;
                  color: $select-link;
                }
              }
            }
            @include breakpoint(small down) {
              width: 75%;
            }
          }
        }
        .fos_comment_comment_replies {
          padding-left: 13%;
          &.max-dept {
            padding-left: 0;
          }
          @include breakpoint(small down) {
            padding-left: 25%;
          }
        }
      }
      .fos_comment_comment_form_holder {
        margin-top: 30px;
        h3 {
          font-size: 12pt;
        }
        textarea {
          min-height: 100px;
          resize: none
        }
        .fos_comment_submit {
          text-align: right;
          input {
            border: none;
            margin: 0 0 10px 10px;
            display: inline-block;
            font-size: 12pt;
            padding: 10px 15px;
            cursor: pointer;
            background: $top-back-yellow;
            &:hover {
              color: $select-link;
            }
          }
        }
      }
      .comment-links {
        text-align: right;
        margin-top: 30px;
        a {
          color: $black;
          margin: 0 0 10px 10px;
          font-size: 12pt;
          padding: 10px 15px;
          span {
            border-bottom: 1px solid $black-border;
          }
          &.yellow {
            background: #f4e4b1;
          }
          &:hover, &.yellow  {
            span {
              border-bottom: none;
            }
          }
          &:hover {
            color: $select-link;
          }
        }
      }
    }
    .page-banner {
      .banner-block {
        display: block;
        @include breakpoint(medium down) {
          display: none;
        }
      }
    }
    .banner-block {
      display: none;
      margin-bottom: 25px;
      img {
        min-width: 100%;
      }
      @include breakpoint(medium down) {
        width: 50%;
        display: block;
        float: left;
      }
      @include breakpoint(small down) {
        width: 100%;
        float: none;
      }
    }
  }
  .other-page-block {
    @include breakpoint(medium down) {
      margin: 0 40px;
    }
    @include breakpoint(small down) {
      margin: 0 20px;
    }
    p {
      font-size: 20pt;
      text-align: center;
    }
  }
  @include breakpoint(medium down) {
    padding: 10px 0 0 0!important;
  }
  @include breakpoint(small down) {
    padding: 0!important;
  }
}

.banner-index {
  background: url("../images/banner-back.jpg") center no-repeat;
  background-size: cover;
  margin: 0 0 40px;
  padding-bottom: 18px;
  .info {
    color: $black;
    font-size: 12pt;
    margin: 0 20px;
    display: flex;
    .image {
      display: inline-block;
      width: 62px;
      margin: 20px 20px 0 0;
      float: left;
      img {
        width: initial;
        max-width: initial;
      }
      @include breakpoint(medium down) {
        margin: 20px 15px 0 0;
      }
    }
    .text-block {
      float: left;
      margin-top: 20px;
      .category {
        font-size: 12pt;
        line-height: 16pt;
      }
      .text {
        font-weight: bold;
        font-size: 18pt;
        line-height: 24pt;
        color: $black;
        @include breakpoint(small down) {
          font-size: 12pt;
          line-height: 16pt;
        }
      }
    }
  }
  @include breakpoint(medium down) {
    margin: 0 0 20px;
  }
  @include breakpoint(small down) {
    margin: 0 0 20px;
  }
}
.loading-block {
  text-align: center;
  margin: 30px auto 0;
  width: 100%;
  .button {
    font-size: 12pt;
    font-weight: bold;
    text-transform: uppercase;
    background: $top-back-yellow;
    width: 300px;
    color: $black;
    &:hover {
      color: $category-name;
    }
  }
  .spinner {
    display: none;
  }
}
.link {
  color: $select-link;
  font-size: 12pt;
  line-height: 12pt;
  border-bottom: 1px dotted $select-lin-border;
  &:hover {
    border-bottom: none;
  }
}

.category-name {
  color: $category-name;
  font-size: 12pt;
  line-height: 16pt;
  &:hover {
    color: $category-name;
  }
}
.footer-container {
  background: $black;
  padding: 30px 0 40px;
  p {
    text-align: center;
    color: $white;
    margin-bottom: 10px;
    font-size: 10pt;
  }
  .menu {
    li {
      font-size: 10pt;
      color: $white;
      a {
        line-height: 0;
        color: $white;
        border-bottom: 1px solid $white-border;
        padding: 0 0 10px;
        margin: 0.7rem 1rem;
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
  .share-block {
    margin-bottom: 10px;
    .ya-share2 {
      text-align: center;
    }
  }
  .footer-links {
  }
  @include breakpoint(medium down) {
    padding: 20px 0 10px;
  }
}

.page-not-found-container {
  max-width: 600px;
  background: $white;
  margin: 10% auto;
  @include breakpoint(medium down) {
  }
  @include breakpoint(small down) {
  }
  .info-block {
    .logo-block {
      height: 30px;
      margin-bottom: 30px;
      img {
        max-height: 100%;
        height: 100%;
      }
    }
    h1 {
      color: $category-name;
      font-size: 30pt;
      margin-bottom: 0;
      line-height: 30pt;
    }
    p {
      margin-bottom: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        a {
          color: $black;
          border-bottom: 1px solid $black-border;
          &:hover {
            color: $category-name;
            border-bottom: none;
          }
        }
      }
    }
  }
}

.popup-container {
  position: relative;
  overflow: hidden;
  top:0!important;
  padding: 0!important;
  .overlay {
    top: 0;
    background-color: $black-opacity-back1;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .close-button {
    z-index: 3;
    color: $closebutton-color!important;
  }
  .login-register-content {
    background: transparent;
    height: 100%;
    .login-register-block {
      position: relative;
      z-index: 3;
      &.login {
        position: relative;
        top: 50%;
        margin-top: -220px;
      }
      h1 {
        color: $white;
      }
      .social-block {
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
      }
      .social-block {
        h3 {
          color: $white;
        }
        li {
          a.fb {
            background-image: url(../images/facebook_white.svg) ;
          }
          a.vk {
            background-image: url(../images/vk_white.svg) ;
          }
          a.gp {
            background-image: url(../images/google_white.svg) ;
          }
          a.ok {
            background-image: url(../images/odnoklassniki_white.svg) ;
          }
          a.tw {
            background-image: url(../images/twitter_white.svg) ;
          }
        }
        .middle-text {
          background-color: $black-opacity-back;
          color: $white;
        }
      }
      .login-register-form label {
        color: $white;
      }
      .other-links {
        a {
          border-bottom-color: $white;
        }
        * {
          color: $white;
        }
      }
    }
  }
}

.vocabulary-block {
  .vocabulary-list {
    margin: 0 auto;
    max-width: 1000px;
    text-align: center;
    li {
      a {
        font-weight: bold;
        color: $select-link;
        padding: 0.3rem 0.4rem;
        margin: 0.1rem 0.2rem;
        font-size: 14pt;
        &:hover {
          color: $black;
          text-decoration: none;
        }
      }
      &.selected {
        a {
          color: $black;
          background: $top-menu-yellow;
          border-radius: 3px;
        }
      }
    }
  }
  .letter-main {
    line-height: 1;
    color: $select-link;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 20pt;
  }
  .words {
    a {
      display: block;
      line-height: 1.7;
      color: $black;
      &:hover {
        color: $select-link;
        text-decoration: none;
      }
    }
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Roboto Slab', serif;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
.index-cell {
  @include xy-cell();
}
.link {
  color: $black;
  border-bottom: 1px solid $black-border;
  &:hover {
    color: $category-name;
    border-bottom: none;
  }
}

/*@include breakpoint(large up) {
  .grid-margin-x > .large-6 {
    width: -webkit-calc(50% - 2.5rem);
    width: calc(50% - 2.5rem);
  }
}*/
