.search-container {
  background: $white;
  padding-bottom: 30px;
  h1 {
    font-size: 30pt;
    font-weight: bold;
    @include breakpoint(medium down) {
      font-size: 20pt;
      margin: 20px 0 40px;
    }
  }
  .total-result {
    font-weight: bold;
    color: $grey;
    margin-bottom: 0;
  }
  .list-controller-block {
    background: $breadcrumbs-grey;
    padding: 20px;
    .list-controller {
      margin: 0;
      list-style: none;
      background: $white;
      padding: 0;
      border: 1px solid $input-border;
      li {
        a {
          display: block;
          padding: 10px 20px;
          color: $grey;
          font-size: 11pt;
        }
        &.select a, a:hover {
          background: $top-back-yellow;
        }
      }
    }
    label {
      font-size: 12pt;
      color: $grey;
    }
    select {
      background-image: url("../images/select-triangle.svg");
      background-size: auto;
      background-position: right -0.5rem center;
      border: 1px solid $input-border;
      line-height: 27pt;
      height: 27pt;
      padding: 0 20px;
      margin: 0 0 0 40px;
      display: inline-block;
      width: 220px;
      color: $grey;
      @include breakpoint(small down) {
        width: 100%;
        margin: 10px 0;
      }
    }
    @include breakpoint(medium down) {
      background: $white;
      margin-bottom: 15px;
      padding-top: 0;
    }
  }
  @include breakpoint(medium down) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include breakpoint(small down) {
    padding-left: 0;
    padding-right: 0;
    .input-group {
      .input-group-button {
        input {
          width: auto;
          padding: 0 20px;
        }
      }
    }
  }
}