@import '../../../node_modules/foundation-sites/scss/foundation';
@import '../../../node_modules/motion-ui/src/motion-ui';
@import '../../../node_modules/jquery-ui/themes/base/core';
@import '../../../node_modules/jquery-ui/themes/base/datepicker';
@import '../../../node_modules/jquery-ui/themes/base/theme';
@include motion-ui-transitions;
@include motion-ui-animations;
@include foundation-everything;

$black: #000;
$black-border: rgba(0,0,0,0.4);
$black-opacity-back: rgba(0,0,0,0.8);
$black-opacity-back1: rgba(0,0,0,0.9);
$white: #fff;
$white-border: rgba(255,255,255,0.4);
$el-back-white: #f4f2f2;
$grey-border: #ccc;
$grey-img-text: #555;
$background-color: #312e2e;
$top-back-yellow: #f4e4b1;
$top-menu-yellow: #e1ca83;
$top-menu-color: #7c7c7c;
$top-menu-color-border: rgba(124,124,124,0.4);
$input-border: #c2c2c2;
$input-border-border: rgba(194,194,194,0.4);
$grey: #565555;
$grey-social: #666666;
$grey-social-border: rgba(102,102,102,0.4);
$breadcrumbs-grey: #e1e1e1;
$breadcrumbs-text: #868484;
$breadcrumbs-text-border: rgba(134,132,132,0.4);
$select-link: #f17553;
$select-lin-border: rgba(241,117,83,0.4);
$category-name: #dd6a4b;
$category-name-border: rgba(221,106,75,0.4);
$visits-gray: #878787;
$error-background: #ffbdbd;
$error-border: #e02f2f;
$error-text: #e02f2f;
$post-background: #14cca4;

.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("../images/ui-icons_444444_256x240.png")/*{iconsContent}*/;
}
.ui-widget-header .ui-icon {
  background-image: url("../images/ui-icons_444444_256x240.png")/*{iconsHeader}*/;
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
  background-image: url("../images/ui-icons_555555_256x240.png")/*{iconsHover}*/;
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
  background-image: url("../images/ui-icons_ffffff_256x240.png")/*{iconsActive}*/;
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
  background-image: url("../images/ui-icons_777620_256x240.png")/*{iconsHighlight}*/;
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("../images/ui-icons_cc0000_256x240.png")/*{iconsError}*/;
}
.ui-button .ui-icon {
  background-image: url("../images/ui-icons_777777_256x240.png")/*{iconsDefault}*/;
}

@mixin underline() {
  color: $black;
  text-decoration: none;
  border-bottom: 1px solid $black-border;
  &:hover {
    border-bottom: none;
    padding-bottom: 1px;
  }
}