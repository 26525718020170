.input-group {
  position: relative;
  input {
    border: 1px solid $input-border;
    line-height: 27pt;
    font-size: 12pt;
    color: $grey;
    height: 27pt;
    padding: 0 15px;
    &.error {
      background: $error-background;
      border-color: $error-border;
    }
  }
  input[type="submit"] {
    background: $black;
    border-radius: 10px;
    font-size: 12pt;
    color: $white;
    line-height: 18pt;
    width: 150px;
    padding: 0;
    &:hover {
      color: $category-name;
    }
  }
  label {
    width: 100%;
    display: block;
    font-size: 12pt;
    line-height: 18pt;
    .input-group-field {
      margin-top: 7px;
      width: 100%;
    }
    .alert {
      color: $error-text;
    }
    &.error {
      position: absolute;
      display: block;
      list-style: none;
      right: -75%;
      top: 0;
      margin: 30px 0 0;
      width: 70%;
      color: $error-text!important;
      font-size: 11pt;
      text-align: left;
      line-height: 14pt;
      text-indent: 0!important;
      @include breakpoint(medium down) {
        right: -55%;
        width: 50%;
      }
      @include breakpoint(small down) {
        position: static;
        width: 100%;
        right: 0;
        margin: 10px 0 0 15px;
      }
    }
  }
  ul {
    display: none;
  }
  &.has-error {
    display: block;
    input {
      background: $error-background;
      border-color: $error-border;
    }
    ul {
      position: absolute;
      display: block;
      list-style: none;
      right: -75%;
      top: 0;
      margin: 30px 0 0;
      width: 70%;
      li {
        color: $error-text;
        font-size: 11pt;
        text-align: left;
        line-height: 14pt;
      }
      @include breakpoint(medium down) {
        right: -55%;
        width: 50%;
      }
      @include breakpoint(small down) {
        position: static;
        width: 70%;
        right: 0;
        margin: 10px 0 0 15px;
      }
    }
  }
}
.search {
  position: relative;
  outline:0;
  margin-right: 15px;
  width: 100%;
  input {
    padding-right: 40px;
    width: 100%;
  }
  .clear-input {
    position: absolute;
    top: 9px;
    right: 10px;
    height: 20px;
    width: 20px;
    background:url('../images/close.png') center no-repeat;
    cursor: pointer;
    outline: none;
  }

  &:not(:valid) ~ .clear-input {
    display: none;
  }
}