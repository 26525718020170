.header-block {
  position: relative;
  background: $top-back-yellow;
  header {
  }
  header {
    padding: 0 80px;
    @media screen and (max-width: 1440px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 1280px) {
      padding: 0 30px;
    }
    @include breakpoint(small down) {
      padding-left: 10px;
      padding-right: 10px;
    }
    &.top-header {
      height: 60px;
      .logo-block {
        float: left;
        height: 100%;
        @include breakpoint(medium down) {
          background: transparent;
        }
        a {
          padding: 0.7rem 3px;
          display: block;
          height: 100%;
          img {
            max-height: 100%;
            height: 100%;
          }
        }
      }
      .fraze-block {
        float: left;
        margin: 15px 0 0 20px;
        width: 37%;
        word-wrap: break-word;
        white-space: normal;
        text-overflow: ellipsis;

        @media screen and (max-width: 1107px) {
          margin: 3px 0 0 10px;
          width: 30%;
        }

        @include breakpoint(medium down) {
          display: none;
        }
      }
      .top-menu {
        float: right;
        li {
          a {
            color: $top-menu-color;
            font-size: 14pt;
            position: relative;
            padding: 0.8rem;
            span {
              display: block;
              float: left;
            }
            .search, .register, .exit {
              margin: 0 10px 0 0;
              svg {
                width: 100%;
                height: 100%;
                fill: $top-menu-color;
              }
              @include breakpoint(medium down) {
                margin: 0;
                svg {
                  fill: $black;
                }

              }
              @media screen and (max-width: 500px) {
                margin: 0 0 0 10px;
              }
            }
            .search {
              width: 19px;
              @include breakpoint(medium down) {
                width: 51px;
                height: 39px;
              }
              @media screen and (max-width: 500px) {
                width: 32px;
                height: 28px;
              }
            }
            .register {
              width: 24px;
              height: 20px;
              @include breakpoint(medium down) {
                width: 51px;
                height: 39px;
              }
              @include breakpoint(small down) {
                display: none;
              }
            }
            .exit {
              width: 20px;
              height: 20px;
              @include breakpoint(medium down) {
                width: 51px;
                height: 39px;
              }
              @include breakpoint(small down) {
                display: none;
              }
            }
            .text {
              @include breakpoint(medium down) {
                display: none;
              }
            }
            &.profile-avatar {
              img {
                margin: -4px 10px 0 0;
                width: 28px;
                border-radius: 50%;
                @include breakpoint(medium down) {
                  width: 43px;
                  margin: -2px 0 0;
                }
                @include breakpoint(small down) {
                  display: none;
                }
              }
              @include breakpoint(medium down) {
                padding-left: 0;
              }
            }
            &:hover {
              span {
                color: $select-link;
                svg {
                  fill: $select-link;
                }
              }
            }
            @include clearfix();
            @include breakpoint(medium down) {
              padding: 0.7rem;
            }
            @media screen and (max-width: 500px) {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
        .toggle-button-link {
          display: none;
          padding-top: 0.7rem;
          margin-left: 10px;
          &.open{
            background: #fff;
            &#main_menu_button {
              background: transparent;
            }
          }
          .menu-icon {
            width: 60px;
            height: 43px;
            outline: none;
            &::after {
              height: 9px;
              background: $black;
              box-shadow: 0 16px 0 $black, 0 31px 0 $black;
              @media screen and (max-width: 500px) {
                height: 4px;
                box-shadow: 0 11px 0 $black, 0 22px 0 $black;
              }
            }
            @media screen and (max-width: 500px) {
              width: 35px;
              height: 26px;
            }
          }
          @include breakpoint(medium down) {
            display: block;
          }
        }
      }
      @media screen and (max-width: 500px) {
        height: 50px;
      }
    }
    &.bottom-header {
      background: $black;
      width: 100%;
      overflow: hidden;
      @include breakpoint(medium down) {
        background: $top-back-yellow;
      }
      .bottom-menu {
        .menu {
          float: left;
          @include breakpoint(medium down) {
            float: none;
            width: 100%;
          }
        }
        li {
          float: left;
          a {
            font-size: 12pt;
            color: #fff;
            @media screen and (max-width: 1440px) {
              padding: 0.7rem 0.8rem;
            }
            @media screen and (max-width: 1280px) {
              font-size: 11pt;
            }
            @media screen and (max-width: 1180px) {
              padding: 0.7rem 0.5rem;
              font-size: 10pt;
            }
            @include breakpoint(medium down) {
              padding: 0.7rem 1rem;
              font-size: 12pt;
              color: $black;
            }
            &:hover {
              color: $select-link;
            }
          }
          &.selected {
            background: $top-menu-yellow;
            @include breakpoint(medium down) {
              background: transparent;
            }
            a {
              color: $black;
            }
          }
        }
        .mobile-login-container {
          margin-bottom: 10px;
          .exit, .register {
            display: block;
            height: 32px;
            width: 28px;
            margin-right: 10px;
          }
          .links {
            float: left;
            display: block;
            margin: 0 20px 0 0;
            color: $visits-gray;
            border-bottom: 1px solid $visits-gray;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              border-bottom: none;
            }
          }
          .exit {
            float: right;
          }
          .register {
            float: left;
            img {
              border-radius: 50%;
            }
          }
          @include breakpoint(medium up) {
            display: none;
          }
        }
        @include breakpoint(medium down) {
          margin: 20px 0;
        }
      }
    }
  }
  #search-block, #register-block {
    display: none;
    color: $black;
    position: absolute;
    width: 600px;
    top: 40px;
    right: 40px;
    background: $top-back-yellow;
    z-index: 3;
    padding: 0 20px;
    @include breakpoint(medium down) {
      position: relative;
      top: 0;
      right: 0;
      width: auto;
    }
    .container {
      @include breakpoint(medium down) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @include breakpoint(small down) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    h4 {
      display: block;
      font-size: 14pt;
      line-height: 20pt;
      font-weight: 700;
      @include breakpoint(medium down) {
        font-size: 12pt;
        line-height: 16pt;
      }
    }
  }
  #search-block {
    input[type="submit"] {
      @include breakpoint(small down) {
        width: auto;
        padding: 0 20px;
      }
    }
  }
  #register-block {
    h4 {
      display: inline-block;
      margin-right: 17px;
    }
    .input-group {
      label {
        display: block;
        width: 100%;
        margin-right: 20px;
        @include breakpoint(medium down) {
          @include clearfix;
          margin: 0 0 20px;
        }
      }

      .input-group-button {
        margin-top: 30px;
        text-align: left;
        @include breakpoint(small down) {
          margin: 0;
          display: block;
        }
      }
      @include breakpoint(medium down) {
        display: block;
      }
    }
  }
  .del-line {
    height: 1px;
    background: #fff;
    margin: 0 -100%;
    display: none;
    @include breakpoint(medium down) {
      display: block;
    }
  }
}