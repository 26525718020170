.profile-edit-block {
  width: 300px;
  margin: 0 auto;
  h1 {
    font-size: 18pt;
    line-height: 20pt;
    font-weight: bold;
    margin-bottom: 40px;
    @include breakpoint(medium down) {
      padding-top: 40px;
    }
  }
  .profile-name-block {
    img {
      border-radius: 50%;
    }
    input {
      display: none;
    }
    a {
      color: $top-menu-color;
      margin-left: 20px;
      border-bottom: 1px solid $top-menu-color-border;
      padding-bottom: 3px;
      &:hover {
        border-bottom: none;
      }
    }
    margin-bottom: 20px;
  }
  .input-group {
    margin-bottom: 20px;
    label {
      color: $top-menu-color;
      text-indent: 15px;
    }
    input {
      padding: 0 15px;
      margin-top: 5px;
    }
    .interest-list {
      border: 1px solid $input-border;
      .interest {
        text-indent: 0;
        cursor: pointer;
        font-size: 12pt;
        padding: 11px;
        color: $top-menu-color;
        input {
          display: none;
        }
        &.selected {
          background: $top-back-yellow;
        }
      }
    }
    .interests-select {
      border: 1px solid $input-border;
      height: 100%;
      padding: 0;
      overflow: visible;
      option {
        font-size: 12pt;
        padding: 11px;
        color: $top-menu-color;
        &.selected {
          background: $top-back-yellow;
        }
      }
    }
  }
  .input-group-button {
    width: 100%;
    input {
      width: 100%;
      display: block;
      text-align: center;
      background: #e1e1e1;
      color: #000;
      border-radius: 0;
      border: none;
    }
  }
  .other-links {
    text-align: center;
    span {
      font-weight: bold;
    }
    & > * {
      color: $black;
      display: inline-block;
      margin-bottom: 20px;
    }
    a {
      @include underline();
    }
  }
}