
.top-list-content {
  .cell {
    margin-bottom: 20px;
    &.blacked {
      position: relative;
      overflow: hidden;
      background: no-repeat center center;
      background-size: cover;
      .item-image {
        img {
          visibility: hidden;
        }
      }
      .item-data {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        background: $black-opacity-back;
        color: $white;
        z-index: 2;
        width: 100%;
        .text {
          color: $white;
          font-size: 18pt;
          line-height: 24pt;
          margin: 0 0 10px;
          font-weight: bold;
          @include breakpoint(medium down) {
            font-size: 12pt;
            line-height: 18pt;
          }
          @media screen and (max-width: 560px) {
            font-size: 10pt;
            line-height: 16pt;
          }
        }
        .triangle {
          width: 200%;
          padding-bottom: 40px;
          position: absolute;
          overflow: hidden;
          top: -40px;
          left: -2px;
          transition: top 0.15s ease, padding-bottom 0.15s ease;
          z-index: 1;
          opacity: 0.8;
          &:before {
            content: '';
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #000;
            transform-origin: left bottom;
            transform: rotate(3.8deg);
            @media screen and (max-width: 1024px) {
              transform: rotate(2.5deg);
            }
            @media screen and (max-width: 800px) {
              transform: rotate(3.2deg);
            }
          }
          @media screen and (max-width: 560px) {
            padding-bottom: 35px;
            top: -35px;
          }
        }
        @media screen and (max-width: 560px) {
          position: relative;
        }
      }
      &.smaller-text {
        .item-data {
          .text {
            font-size: 12pt;
            line-height: 18pt;
            min-height: 107px;
          }
          .triangle {
            padding-bottom: 35px;
            top: -35px;
          }
        }
        &:hover {
          .item-data {
            .triangle {
              padding-bottom: 45px;
              top: -45px;
            }
          }
        }
      }
      &:hover {
        .item-data {
          .triangle {
            padding-bottom: 50px;
            top: -50px;
          }
        }
      }
    }
    &.lighted {
      background: $el-back-white;
      position: relative;
      .item-data {
        padding: 20px 20px 30px 20px;
        .category {
          font-weight: bold;
        }
        .text {
          min-height: 85px;
          font-weight: 300;
          &:hover {
            color: $category-name;
          }
        }
      }
      &.middle-text {
        .item-data {
          .text {
            font-size: 11pt;
            line-height: 16pt;
            min-height: 65px;
          }
        }
      }
      &:hover {
        .item-data {
          .text {
            color: $category-name;
          }
        }
      }
    }
    &.split-result {
      .item-image {
        float: left;
        width: 30%;
        @include breakpoint(small down) {
          display: none;
        }
      }
      .item-data {
        float: left;
        width: 70%;
        height: 100%;
        .category {
          font-weight: bold;
        }
        .visits, .comments {
          bottom: 0;
          padding: 0;
        }
        &.no-image {
          width: 100%;
        }
        @include breakpoint(small down) {
          width: 100%;
          padding: 0 0 0 10px;
          margin-bottom: 20px;
          border-left: $top-menu-yellow solid 10px;
          .text {
            font-weight: bold;
            min-height: 60px;
            &:hover {
              color: $category-name;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          .visits, .comments {
            position: relative;
            padding-bottom: 0;
            margin-top: 5px;
          }
        }
      }
    }
    .item-image {
      img {
        width: 100%;
      }
    }
    .item-data {
      padding: 0 20px 20px;
      position: relative;
      .category {
        color: $category-name;
        font-size: 12pt;
        line-height: 16pt;
      }
      .text {
        color: $black;
        font-size: 12pt;
        line-height: 16pt;
        margin: 0;
        font-weight: bold;
      }
      .info-block {
        position: absolute;
        bottom: 0;
        display: block;
      }
      .visits, .comments  {
        color: $visits-gray;
        font-size: 8pt;
        line-height: 16pt;
        padding-bottom: 5px;
        display: inline-block;
        margin-right: 10px;
        &:before {
          content: '';
          width: 16px;
          height: 12px;
          background: url("../images/eye-close-up.png") no-repeat;
          display: inline-block;
          margin: 0 10px -2px 0;
        }
      }
      .comments {
        &:before {
          width: 16px;
          height: 12px;
          background: url("../images/speech-message.svg") no-repeat;
          background-size: contain;
        }
      }
      &.info-content {
        padding: 0 0 0 10px;
        margin-bottom: 20px;
        border-left: $top-menu-yellow solid 10px;
        .category {
          font-weight: bold;
        }
        .text {
          font-weight: 300;
          min-height: 60px;
          &:hover {
            color: $category-name;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        .info-block {
          position: relative;
        }
        .visits, .comments {
          padding-bottom: 0;
          margin-top: 5px;
        }
      }
      @include breakpoint(medium down) {
        padding-bottom: 30px;
      }
    }
    &:hover {
      .item-image {
        img {
          filter: grayscale(100%);
        }
      }
    }
  }
/*  &.grid-x .cell {
    @include breakpoint(medium up) {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
    @include breakpoint(medium down) {
      @include xy-gutters($gutters: $grid-padding-gutters, $gutter-type: margin);
      //@include grid-row-nest();
    }
  }*/
  /*&.grid-margin-x > .large-3{
    @include breakpoint(medium up) {
      width: calc(25% - 1.255rem);
    }
    @include breakpoint(medium down) {
      @include xy-cell();
    }
  }
  &.grid-margin-x > .large-4{
    @include breakpoint(medium up) {
      width: calc(33.3333333333% - 1.255rem);
    }
    @include breakpoint(medium down) {
      @include xy-cell();
    }
  }
  &.grid-margin-x > .large-6 {
    @include breakpoint(medium up) {
      width: calc(50% - 1.3rem);
    }
    @include breakpoint(medium down) {
      @include xy-cell();
    }
  }*/
}